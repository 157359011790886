import React from "react";
import { styled } from "goober";

import { appURLs } from "@/app/constants.mjs";

export const IllustrationWrapper = styled("div")`
  position: relative;

  flex: 1;

  max-width: 100%;
  min-height: 200px;
  max-height: 416px;
  aspect-ratio: 1.6;
  margin-bottom: var(--sp-3);
`;

export const IllustrationVideo = styled("video")`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: block;
  height: 100%;
  margin: 0 auto;
`;

export function Illustration() {
  return (
    <IllustrationWrapper>
      <IllustrationVideo
        autoPlay
        muted
        loop
        playsInline
        poster={`${appURLs.CDN}/blitz/ui/img/auth/landing-f485ffea.webp`}
      >
        <source
          src={`${appURLs.CDN_VIDEOS}/ui/video/auth/landing-e3fb302c.webm`}
          type="video/webm"
        />
      </IllustrationVideo>
    </IllustrationWrapper>
  );
}
