import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";
import { Button, ButtonGroup } from "clutch/src/Button/Button.jsx";
import { TextField } from "clutch/src/TextField/TextField.jsx";

import { OAUTH2_PROVIDERS } from "@/feature-auth/constants/constants.mjs";
import testId from "@/util/test-id.mjs";

export const Form = styled("form")`
  display: flex;
  flex-direction: column;

  width: 360px;

  text-align: center;

  h1 {
    margin-bottom: var(--sp-3);

    color: var(--shade0);
  }

  p {
    margin-bottom: var(--sp-6);

    color: var(--shade2);
  }

  .button-group {
    margin-bottom: var(--sp-10);
  }

  input {
    margin-bottom: var(--sp-4);
  }

  footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      margin-right: var(--sp-2);

      color: var(--shade3);
    }

    button,
    a {
      padding: 0;

      color: var(--shade1);

      background: none;

      cursor: pointer;
    }
  }
`;

export type OAuth2Provider = { id: string; logo: SVGComponent; color: string };

const OAuth2ButtonGrid = styled("div")`
  display: flex;
  gap: var(--sp-4);

  button:hover {
    filter: brightness(120%);
  }
`;

type AuthenticateFormProps = {
  onSubmit: (email: string) => void;
  startOAuth2Flow: (provider: string) => void;
};

export default function AuthenticateForm({
  onSubmit: onSubmitted,
  startOAuth2Flow,
}: AuthenticateFormProps) {
  const [email, setEmail] = useState("");

  const onChangeEmail = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      setEmail(event.currentTarget.value);
    },
    [setEmail],
  );

  const onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      if (e.type !== "submit") return;

      e.preventDefault();

      if (!email) return;

      onSubmitted(email);
    },
    [email, onSubmitted],
  );

  const { t } = useTranslation();

  return (
    <Form onSubmit={onSubmit} {...testId("authenticate-form")}>
      <h1 className="type-h5 pad-sp-2">
        {t("common:welcomeToBlitz", "Welcome to Blitz")}
      </h1>
      <TextField
        type="email"
        name="email"
        required
        placeholder={t("common:account.yourEmailAddress", "Your Email Address")}
        onInput={onChangeEmail}
        value={email}
      />
      <ButtonGroup>
        <Button
          type="submit"
          block
          emphasis="high"
          size="large"
          active={!email.includes("@")}
        >
          {t("common:continue", "Continue")}
        </Button>
      </ButtonGroup>
      <OAuth2ButtonGrid>
        {Object.values(OAUTH2_PROVIDERS).map((provider: OAuth2Provider) => {
          return (
            <Button
              key={provider.id}
              type="button"
              onClick={() => startOAuth2Flow(provider.id)}
              bgColor={provider.color}
              size="large"
              block
            >
              <provider.logo />
            </Button>
          );
        })}
      </OAuth2ButtonGrid>
    </Form>
  );
}

export function meta() {
  return {
    title: ["common:navigation.signIn", "Sign In"],
    description: [],
  };
}
