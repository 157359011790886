import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { styled } from "goober";
import { Button, ButtonGroup } from "clutch/src/Button/Button.jsx";

import {
  Content,
  SecretCard,
} from "@/feature-auth/components/VerifyCode.style.jsx";

interface VerifyCodeProps {
  email: string;
  secretCode: string;
  onResend: () => void;
  onCancel: () => void;
}

const Undo = styled("button")`
  color: var(--primary) !important;

  background: transparent;

  cursor: pointer;
`;

export default function VerifyCode({
  email,
  secretCode,
  onResend,
  onCancel,
}: VerifyCodeProps) {
  const { t } = useTranslation();

  return (
    <Content>
      <h1 className="type-h5">
        {t("common:account.verifyEmail", "Verify your Email")}
      </h1>

      <p className="type-caption">
        <Trans i18nKey="common:account.verifyEmailDescription" email={email}>
          We sent an email to {{ email }} <Undo onClick={onCancel}>Undo</Undo>
          <br />
          Check that the security code below matches the email:
        </Trans>
      </p>

      <SecretCard>
        {secretCode ?? (
          <>
            {/* adds a space so the code doesn't change the height of the container when it's inserted */}
            &nbsp;
          </>
        )}
      </SecretCard>

      <ButtonGroup>
        <Button block size="large" onClick={onResend}>
          {t("common:signup.resendEmail", "Resend Email")}
        </Button>

        {email.endsWith("gmail.com") && (
          <Button
            block
            size="large"
            emphasis="high"
            href="https://mail.google.com/"
            target="_blank"
          >
            {t("common:account.openInGmail", "Open in Gmail")}
          </Button>
        )}
      </ButtonGroup>
    </Content>
  );
}
