import { styled } from "goober";
import { Checkbox } from "clutch/src/Checkbox/Checkbox.jsx";

export const Header = styled("header")`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: var(--sp-6);

  max-width: 392px;
  padding: var(--sp-4);
  padding-block-start: var(--page-end);

  text-align: center;

  h1 {
    color: var(--shade0);
  }

  p {
    color: var(--shade1);
  }

  .hero {
    width: 100%;
    height: 148px;
  }
`;

export const Form = styled("form")`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--sp-6);

  max-width: 392px;
  padding-inline: var(--sp-4);

  & > * {
    width: 100%;
  }
`;

export const FormInput = styled("div")`
  label {
    margin-bottom: var(--sp-2);

    color: var(--shade1);
  }

  & > * {
    width: 100%;
  }
`;

export const MultipleInputs = styled("div")`
  display: flex;
  gap: var(--sp-4);
`;

export const BirthdayInputs = styled(MultipleInputs)`
  & > *:first-child {
    flex: 1.5;
  }

  & > *:nth-child(2) {
    flex: 0.75;
  }
`;

export const RegionGenderInputs = styled(MultipleInputs)`
  & > * {
    width: 100%;
  }
`;

export const AgreementCheckbox = styled(Checkbox)`
  color: var(--shade3);

  a {
    color: var(--shade1);
  }

  a:hover {
    color: var(--shade0);
  }
`;
