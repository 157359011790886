import { styled } from "goober";

export const Content = styled("div")`
  display: flex;
  flex-direction: column;

  width: 360px;

  text-align: center;

  h1 {
    margin-bottom: var(--sp-3);

    color: var(--shade0);
  }

  p {
    margin-bottom: var(--sp-6);

    color: var(--shade2);
  }

  section {
    margin-bottom: var(--sp-7);
  }
`;

export const SecretCard = styled("section")`
  position: relative;

  width: 100%;
  margin-top: var(--sp-2);
  padding: var(--sp-3_5);

  color: var(--shade1);
  text-align: center;

  background: var(--shade7);
  border-radius: var(--br, 5px);

  overflow: hidden;
`;
