import type { FormEvent } from "react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { css } from "goober";
import { Button } from "clutch/src/Button/Button.jsx";
import { Dropdown } from "clutch/src/Dropdown/Dropdown.jsx";
import { TextField } from "clutch/src/TextField/TextField.jsx";

import AuthorizationGranted from "@/feature-auth/components/AuthorizationGranted.jsx";
import {
  AgreementCheckbox,
  BirthdayInputs,
  Form,
  FormInput,
  Header,
  RegionGenderInputs,
} from "@/feature-auth/components/OnboardingForm.style.jsx";
import { updateUserInfo } from "@/feature-auth/fetches/update-user.mjs";
import type { NewUser } from "@/feature-auth/models/user-model.mjs";
import { classNames } from "@/util/class-names.mjs";
import { devError } from "@/util/dev.mjs";
import {
  getDaysInMonth,
  getLastNumberOfYears,
  getMonthNames,
} from "@/util/dob-dates.mjs";
import { getRegions } from "@/util/regions.mjs";
import useCountryCode from "@/util/use-country-code.mjs";
import { isValidAge } from "@/util/validate-age.mjs";

const cssInputError = () => css`
  position: absolute;
  bottom: 0;

  padding-top: var(--sp-0_5);

  color: var(--primary);
  font-weight: 400;
  font-size: var(--sp-3);

  opacity: 0;

  transform: translateY(50%);

  transition:
    opacity 0.25s,
    transform 0.25s;

  &.shown {
    opacity: 1;

    transform: translateY(100%);
  }
`;

type OnboardingFormProps = {
  user: NewUser;

  returnToApp: () => void;
};

// TODO: i18n - months are not translated/DMY vs MDY format?/other year era?
export default function OnboardingForm(props: OnboardingFormProps) {
  const { t } = useTranslation();

  return (
    <>
      <Header>
        <div className="hero">
          <AuthorizationGranted />
        </div>

        <h1 className="type-h1">
          {t("common:account.authorizationGranted", "Authorization Granted")}
        </h1>

        <p className="type-body2">
          {t(
            "common:account.onboarding.cta",
            "We just need some details to get your Blitz account up and running",
          )}
        </p>
      </Header>

      <OnboardingInputs {...props} />
    </>
  );
}

function OnboardingInputs({ user, returnToApp }: OnboardingFormProps) {
  const {
    i18n: { language },
  } = useTranslation();

  const isPostSignUpOnboarding = !!user.name;

  const [isUsernameTaken, setUsernameTaken] = useState(false);

  const [username, setUsername] = useState(user.name ?? "");
  const [birthday, setBirthday] = useState<Date>(user.birthday ?? null);
  const [gender, setGender] = useState(user.gender ?? undefined);
  const [location, setLocation] = useState(user.location);

  const detectedCountryCode = useCountryCode();

  useEffect(() => {
    if (!detectedCountryCode) return;

    setLocation(detectedCountryCode);
  }, [detectedCountryCode, setLocation]);

  const [agreed, setAgreed] = useState<boolean>(false);

  const onChangeUsername = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setUsername(event.target.value);

      setUsernameTaken(false);
    },
    [setUsername, setUsernameTaken],
  );

  const regions = useMemo(() => {
    return getRegions(language);
  }, [language]);

  const onSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      try {
        if (!username) return false;
        if (!birthday) return false;
        if (!location) return false;
        if (gender === undefined) return false;

        await updateUserInfo({
          name: username,
          birthday,
          location,
          gender,
        });

        await returnToApp();
      } catch (e) {
        if (
          typeof e === "object" &&
          "message" in e &&
          typeof e["message"] === "string" &&
          e["message"].indexOf("name has already been taken") !== -1
        ) {
          setUsernameTaken(true);
        } else {
          devError("Failed to submit onboarding form", e);
        }
      }
    },
    [username, birthday, location, gender, returnToApp],
  );

  const { t } = useTranslation();

  return (
    <Form onSubmit={onSubmit}>
      <FormInput>
        <label className="type-caption" htmlFor="email">
          {t("common:signup.insertEmail", "Email Address")}
        </label>

        <TextField
          id="email"
          type="email"
          name="email"
          autoComplete="email"
          required
          placeholder={t(
            "common:account.yourEmailPlaceholder",
            "Your Email Address...",
          )}
          value={user.email}
          disabled
        />
      </FormInput>

      {!user.name && (
        <FormInput>
          <label className="type-caption" htmlFor="username">
            {t("common:signup.username", "Username")}
          </label>

          <TextField
            id="username"
            name="username"
            required
            minLength={3}
            maxLength={15}
            error={isUsernameTaken && "Username taken"}
            placeholder={t("common:signup.username", "Username")}
            value={username}
            onChange={onChangeUsername}
          />
        </FormInput>
      )}

      <BirthdayInput birthday={birthday} setBirthday={setBirthday} />

      <RegionGenderInputs>
        <FormInput>
          <label className="type-caption" htmlFor="region">
            {t("common:signup.keys.region", "Region")}
          </label>

          <Dropdown
            id="region"
            name="region"
            autoComplete="country"
            required
            placeholder={t("common:pleaseSelect", "Please select")}
            search
            maxHeight={400}
            items={regions}
            value={location}
            onChange={setLocation}
            block
          />
        </FormInput>

        <FormInput>
          <label className="type-caption" htmlFor="gender">
            {t("common:account.gender", "Gender")}
          </label>

          <Dropdown
            id="gender"
            name="gender"
            autoComplete="sex"
            required
            placeholder={t("common:pleaseSelect", "Please select")}
            items={[
              {
                value: "MALE",
                label: t("common:account.genders.male", "Male"),
              },
              {
                value: "FEMALE",
                label: t("common:account.genders.female", "Female"),
              },
              {
                value: null,
                label: t("common:account.genders.none", "Prefer not to say"),
              },
            ]}
            value={gender}
            onChange={setGender}
            block
          />
        </FormInput>
      </RegionGenderInputs>

      <AgreementCheckbox
        required
        checked={agreed}
        onChange={(bool: boolean) => setAgreed(bool)}
      >
        <Trans i18nKey="common:account.agreeTosPp">
          I agree to the{" "}
          <a href="/legal/terms-of-service" target="_blank">
            Terms of Service
          </a>{" "}
          and{" "}
          <a href="/legal/privacy-policy" target="_blank">
            Privacy Policy
          </a>
        </Trans>
      </AgreementCheckbox>

      <Button type="submit" emphasis="high" size="large">
        {!isPostSignUpOnboarding
          ? t("common:navigation.signUp", "Sign Up")
          : t("common:continue", "Continue")}
      </Button>
    </Form>
  );
}

type BirthdayInputProps = {
  birthday: Date | null;

  setBirthday: (date: Date) => void;
};

function BirthdayInput({ birthday, setBirthday }: BirthdayInputProps) {
  const [day, setDay] = useState<number | undefined>(birthday?.getUTCDate());

  const [month, setMonth] = useState<number | undefined>(
    birthday?.getUTCMonth(),
  );

  const [year, setYear] = useState<number | undefined>(
    birthday?.getUTCFullYear(),
  );

  const days = useMemo(() => {
    const date = birthday ? new Date(birthday) : new Date();

    return getDaysInMonth(year ?? date.getFullYear(), month ?? date.getMonth());
  }, [birthday, year, month]);

  const months = useMemo(() => {
    return getMonthNames();
  }, []);

  const years = useMemo(() => {
    return getLastNumberOfYears(100);
  }, []);

  const [shouldShowError, setShouldShowError] = useState(false);

  const onInvalid = useCallback((_e: React.FormEvent<HTMLSelectElement>) => {
    setShouldShowError(true);
  }, []);

  const isInAgeRange = isValidAge(year, month, day);

  useEffect(() => {
    if (isInAgeRange) {
      setBirthday(new Date(year, month, day));
      setShouldShowError(false);
    } else {
      setBirthday(null);
    }
  }, [isInAgeRange, year, month, day, setBirthday]);

  const { t } = useTranslation();

  const ageError =
    isInAgeRange === false || shouldShowError
      ? t(
          "common:account.tooYoungError",
          "You must be 13 years of age or older!",
        )
      : false;

  return (
    <FormInput>
      <label className="type-caption">
        {t("common:signup.birthDate", "Date of Birth")}
      </label>

      <BirthdayInputs>
        <Dropdown
          name="month"
          autoComplete="bday-month"
          error={ageError}
          required
          placeholder={t("common:signup.keys.month", "Month")}
          items={months}
          value={month}
          onChange={setMonth}
          onInvalid={onInvalid}
          block
        />

        <Dropdown
          name="day"
          autoComplete="bday-day"
          error={ageError}
          required
          placeholder={t("common:signup.keys.day", "Day")}
          items={days}
          value={day}
          onChange={setDay}
          onInvalid={onInvalid}
          block
        />

        <Dropdown
          name="year"
          autoComplete="bday-year"
          error={ageError}
          required
          placeholder={t("common:signup.keys.year", "Year")}
          items={years}
          value={year}
          onChange={setYear}
          onInvalid={onInvalid}
          block
        />
      </BirthdayInputs>

      <div className={"relative"}>
        <div
          role="alert"
          {...classNames(cssInputError(), ageError ? "shown" : "")}
          aria-hidden
        >
          {ageError}
        </div>
      </div>
    </FormInput>
  );
}
